<template>
  <section
    class="text-section"
    :class="[variant ? `text-section--${variant}` : '']"
  >
    <div
      class="text-section__content paragraph"
      :class="[`text-section__content--${alignment}`]"
    >
      <div
        v-if="header"
        v-html="header"
        class="text-section__heading"
      ></div>
      <p
        v-if="text"
        v-html="text"
        class="text-section__text"
      ></p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TextSection',
  props: {
    header: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    },
    variant: {
      type: String
    },
    alignment: {
      type: String,
      default: 'center'
    }
  }
}
</script>
