<template>
  <div class="hero-banner-item">
    <div
      class="hero-banner-item__content"
      :class="[`hero-banner-item__content--${data.alignText}`]"
    >
      <div
        v-if="data.paragraph"
        class="hero-banner-item__text paragraph"
        :class="[
          `hero-banner-item__text--${data.textColor === 'black' ? 'black' : 'white'}`,
          `hero-banner-item__text--${data.alignText}`
        ]"
        v-html="data.paragraph"
      ></div>
      <a
        v-if="data.link"
        @click.prevent="$emit('navigationRequested')"
        v-html="data.link.title"
        :href="data.link.url"
        class="button hero-banner-item__button"
        :class="[`button--primary${data.textColor === 'black' ? '-black' : ''}`]"
      ></a>
    </div>
    <img
      v-if="image"
      :src="image"
      :width="isMobile ? 375 : 1440"
      :height="isMobile ? 325 : 430"
      loading="lazy"
      :title="data.imageDescription"
      :alt="data.imageAlt"
      class="hero-banner-item__image-bg"
    />
  </div>
</template>

<script>
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'HeroBannerItem',
  mixins: [ScreenWidthMixin],
  props: ['image', 'data']
}
</script>
