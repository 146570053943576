<template>
  <div class="catalog" v-if="catalogData">

    <HeroBanner
      :isDecorated="isHeroBannerAvailable"
      :title="catalogData.title"
      :data="isHeroBannerAvailable ? catalogData.content.heroBanner : null"
      :parentKey="catalogData.key"
    />

    <div class="container">
      <slot name="crumbs" />
    </div>

    <TextSection
      v-if="catalogData.description"
      :text="catalogData.description"
      variant="catalog-view"
    />

    <search-component
      :showSearchQueryInput='false'
      :facetsToHide="[catalogData.rootGroup]"
      :facetValuesToApply="[catalogData.indexTitle]"
      :showAppliedFacetChips=false
      :showItemCategories=false
      :showInitiallyEmptyFacets=false
      :isCatalog=true
      :catalogOriginalTitle="catalogData.originalTitle"
      :bannerAd="catalogData.content ? catalogData.content.bannerAd : null"
      :showBannerAd="catalogData.showBannerAd"
      @initialized="onChildInitialized"
      :parentKey="catalogData.key"
    />
  </div>
  <loader v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import ScrollSearchPageByQueryMixin from '@/mixins/ScrollSearchPageByQueryMixin'
import CatalogPageMixin from '@/mixins/CatalogPageMixin'
import PageMixin from '@/mixins/PageMixin'
import HeroBanner from '@/components/HeroBanner'
import TextSection from '@/components/TextSection'
import SearchComponent from '@/components/search/SearchComponent'

export default {
  name: 'CatalogProducts',
  components: {
    HeroBanner,
    TextSection,
    SearchComponent
  },
  mixins: [PageMixin, ScreenWidthMixin, ScrollSearchPageByQueryMixin, CatalogPageMixin],
  computed: {
    ...mapState('catalog', ['catalogData']),
    pageTitle () {
      return this.catalogData?.meta?.title
    },
    isHeroBannerAvailable () {
      return !!(this.catalogData.isDecorated && this.catalogData.content.heroBanner)
    },
    metaData () {
      return this.catalogData?.meta
    }
  },
  methods: {
    ...mapActions('catalog', ['setCatalogData', 'unsetCatalogData']),
    ...mapMutations('catalog', { unsetCatalogData: 'UNSET_CATALOG_DATA' }),
    // @TODO finish the control gate or remove completely
    onChildInitialized () {
      this.isInitialized = true
    }
  },
  async created () {
    this.unsetCatalogData()

    const catalogId = this.$route.params.pathMatch
    await this.setCatalogData(catalogId)

    if (!this.catalogData) {
      this.$router.push('/404')
    }

    this.setPageTitle(this.pageTitle)
    this.setMetaTags()
  },
  beforeDestroy () {
    this.unsetMetaTags()
  },
  data () {
    return {
      clearanceData: null,
      isInitialized: false
    }
  }
}
</script>
