import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('catalog', ['catalogData']),
    ...mapState('catalogs', ['catalogsData']),
    pageCrumbs () {
      return this.catalogData?.parentKeys
        ? this.catalogData.parentKeys
          .map(key => this.catalogsData.allCatalogs.find(el => el.key === key))
          .map((el, idx, arr) => {
            const isRootCategoryCatalog = idx === 0
            const catalogNameByType = this.catalogData.type === 'brand' ? 'brands' : 'categories'
            const dropdownData = isRootCategoryCatalog
              ? this.catalogsData[catalogNameByType]
              : arr[idx - 1].subValues

            const constructDropdownItem = value => {
              return {
                key: value.key,
                url: value.url,
                title: value.title,
                isDropDownOpen: false,
                dropdownData: value.subValues?.length
                  ? value.subValues.map(constructDropdownItem)
                  : null
              }
            }
            return {
              id: el.key,
              title: el.title,
              url: el.url,
              hasAction: idx !== arr.length - 1,
              isDropDownOpen: false,
              dropdownData: dropdownData.map(el => {
                return {
                  isDropDownOpen: false,
                  key: el.key,
                  url: el.url,
                  title: el.title,
                  dropdownData: el.subValues?.length
                    ? el.subValues.map(constructDropdownItem)
                    : null
                }
              }).sort((a, b) => a.title.localeCompare(b.title, 'en', { numeric: true }))
            }
          })
        : []
    }
  }
}
