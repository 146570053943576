<template>
  <section
    class="hero-banner"
    :class="{ 'hero-banner--no-image': !isDecorated }"
    ref="banner"
  >
    <div v-if="!isDecorated" class="hero-banner__no-content">
      <h1 class="text-h1-capitalized" v-html="title"></h1>
    </div>

    <HeroBannerItem
      v-else-if="isDecorated && data.type === 'blockBannerContent'"
      :data="data"
      :image="backgroundImage"
      @navigationRequested="data.link && data.link.url ? handleClick(data.link) : null"
    />

    <a
      v-else-if="isDecorated && data.link && data.type === 'blockBannerImage'"
      @click.prevent="data.link && data.link.url ? handleClick(data.link) : null"
      :href="data.link && data.link.url ? data.link.url : '/'"
      class="hero-banner__image-wrap-link"
    >
      <img class="hero-banner__image image-banner__img" alt="hero-image" :src="backgroundImage" loading="lazy" />
    </a>

    <img
      v-else
      :src="backgroundImage"
      loading="lazy"
      class="hero-banner__image image-banner__img" alt="hero-image"
    />
  </section>
</template>
<script>
import _ from 'lodash'
import { mapActions } from 'vuex'

import { checkIsElementInView } from '@/helpers'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import HeroBannerItem from '@/components/HeroBannerItem'

export default {
  name: 'HeroBanner',
  components: {
    HeroBannerItem
  },
  mixins: [NavigationalMixin, ScreenWidthMixin],
  props: {
    isDecorated: {
      type: Boolean,
      required: true
    },
    title: {
      type: String
    },
    data: {
      type: Object
    },
    parentKey: {
      type: String,
      required: true,
      default: ''
    },
    enableInnerImpressionPromotionAnalytics: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    backgroundImage () {
      return this.isMobile && this.data.imageMobile ? this.data.imageMobile : this.data.image
    },
    isPromotionImpressionAnalyticsEnabled () {
      return this.isDecorated && this.enableInnerImpressionPromotionAnalytics && this.data.isPromotionComponent
    },
    isPromotionClickAnalyticsEnabled () {
      return this.isDecorated && this.data.isPromotionComponent
    },
    promotionAnalyticsData () {
      return {
        name: this.data.key,
        position: this.parentKey,
        creative: this.data.image
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['populatePromotionClickAnalytics', 'populatePromotionImpressionAnalytics']),
    handleClick (link) {
      if (this.isPromotionClickAnalyticsEnabled) this.populatePromotionClickAnalytics(this.promotionAnalyticsData)
      this.emitClickMode ? this.$emit('clicked') : this.navigateTo(link.url)
    },
    scrollListener: _.throttle(function () {
      if (this.isPromotionImpressionAnalyticsEnabled) {
        const isBannerInView = checkIsElementInView(this.$refs.banner, 50)
        if (isBannerInView) {
          this.sendPromotionImpressionAnalytics()
          window.removeEventListener('scroll', this.scrollListener)
        }
      }
    }, 500),
    sendPromotionImpressionAnalytics () {
      if (this.isDecorated && this.data.isPromotionComponent) {
        this.populatePromotionImpressionAnalytics(this.promotionAnalyticsData)
      }
    }
  },
  mounted () {
    if (this.isPromotionImpressionAnalyticsEnabled) {
      this.$nextTick(function () {
        const isBannerInView = checkIsElementInView(this.$refs.banner, 50)
        if (isBannerInView) {
          this.sendPromotionImpressionAnalytics()
        } else {
          window.addEventListener('scroll', this.scrollListener)
        }
      })
    }
  },
  beforeDestroy () {
    if (this.isPromotionImpressionAnalyticsEnabled) window.removeEventListener('scroll', this.scrollListener)
  }
}
</script>
